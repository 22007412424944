
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import type { StatusType } from '@/definitions/shared/types'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
    TmStatus,
  },
  props: {
    title: {
      type: String,
    },
    backFunc: {
      type: Function,
    },
    nextFunc: {
      type: Function,
    },
    description: {
      type: String,
    },
    image: {
      type: String,
    },
    statuses: {
      type: Array as PropType<StatusType[]>,
    },
    statusesLabel: {
      type: String,
    },
  },
  setup(props) {
    const modal = ref()

    const handleClick = () => {
      modal.value.hideModal()
      if (typeof props.nextFunc === 'function') {
        props.nextFunc()
      }
    }
    const handleBack = () => {
      modal.value.hideModal()
      if (typeof props.backFunc === 'function') {
        props.backFunc()
      }
    }

    return {
      handleBack,
      handleClick,
      modal,
    }
  },
})
